import { apolloClient } from '../../../plugins/vue-apollo';
import gql from 'graphql-tag';


let loadingLeads = false;

export default {
    async loadUnities({ commit }) {
        return apolloClient.query({
            query: gql`
                query Unidades {
                    unities {
                        id
                        title
                        departments {
                            id
                            title
                        }
                    }                    
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ unities }) => {
            commit('setUnities', unities);
        }).catch(err => {
            console.error(err);
        });
    },
    async loadPlatforms({ commit }) {
        return apolloClient.query({
            query: gql`
                query Platforms {
                    platforms {
                        id
                        title
                        logo
                    }
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ platforms }) => {
            commit('setPlatforms', platforms);
        }).catch(err => {
            console.error(err);
        });
    },
    async loadUsers({ commit }) {
        const filter = {
            types: ['CORRETOR', 'GERENTE']
        };
        return apolloClient.query({
            variables: {
                filter
            },
            query: gql`
                query Users($filter: UserInput) {
                    users(filter: $filter) {
                        id
                        name
                        unities {
                            unity {
                                id
                            }
                            department {
                                id
                            }
                        }
                    }
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                
                throw error;
            }
            return data;
        }).then(({ users }) => {
            commit('setUsers', users);
        }).catch(err => {
            console.error(err);
        });
    },
    async loadLeads({ commit }, {unity_id = null, department_id = null, origin_id = null, user_id = null, periodo = null, after = null, count = 40, refresh = false} = {}) {
        const filter = {
            unity: {
                id: unity_id
            },
            department: {
                id: department_id
            },
            origin: {
                id: origin_id
            },
            assignedTo: {
                id: user_id
            },
            periodo
        };

        

        
        if ((!after || ''  == after) && !refresh) {
            commit('setLeads', []);
        }

        if (loadingLeads) {
            return;
        }

        loadingLeads = true;
        

        return apolloClient.query({
            variables: {
                filter,
                after,
                count
            },
            query: gql`
                query Leads($filter: LeadInput, $after: ID, $count: Int) {
                    leads(filter: $filter, after: $after, count: $count) {
                        id
                        date,
                        name
                        email
                        phone
                        message
                        property {
                            code
                            title
                        }
                        assignedTo {
                            id
                            name
                            email
                            phone
                        }
                        origin {
                            id
                            title
                        }
                        realEstate {
                            id
                            title
                        }
                        unity {
                            id
                            title
                        }
                        department {
                            id
                            title
                        }
                        history {
                            data
                            descricao
                        }
                    }
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ leads }) => {
            commit('addLeads', leads);
            
        }).catch(err => {
            console.error(err);
            throw err;
        }).then(() => {
            loadingLeads = false;
        });
    },
    async sendToUser({ commit, dispatch }, { user_id, lead_id }) {
        return apolloClient.mutate({
            variables: {
                user_id,
                lead_id
            },
            mutation: gql`
                mutation sendToUser($lead_id: ID, $user_id:ID) {
                    lead:sendToUser(lead_id:$lead_id, user_id:$user_id) {
                        id
                        assignedTo {
                            id
                            name
                            email
                            phone
                        }
                    }
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ lead }) => lead);
    },
    async sendSelectedToUser({ commit, dispatch }, { user_id, leads }) {
        return apolloClient.mutate({
            variables: {
                user_id,
                leads
            },
            mutation: gql`
                mutation sendSelectedToUser($user_id: ID, $leads: [ID]) {
                    sendSelectedToUser(user_id: $user_id, leads: $leads)
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ sendSelectedToUser }) => sendSelectedToUser);
    },
    async loadRetainedLeads({ commit }) {
        return apolloClient.query({
            query: gql`
                query RetainedLeads {
                    retainedLeads
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        })
        .then(({ retainedLeads }) => {
            commit('setRetainedLeads', retainedLeads);
        })
        .catch(err => {
            console.error(err);
        })
    },
    async saveLead({ dispatch }, lead) {
        return apolloClient.mutate({
            variables: {
                lead
            },
            mutation: gql`
                mutation SaveLead($lead: LeadInput) {
                    saveLead(lead: $lead) {
                        code
                        success
                        message
                        lead {
                            id
                        }
                    }
                }
            `
        }).then(({ data, errors }) => {
            if (errors) {
                let [error] = errors;
                throw error;
            }
            return data;
        }).then(({ saveLead: { code, success, message, lead }}) => {
            if (!success) {
                throw new Error(message||"Não foi possível enviar o formulário.");
            }
            return lead;
        }).catch(err => {
            console.error(err);
            throw err;
        });
    },
    async updateLead({ commit, state }, lead) {
        if (null == lead) {
            return;
        }
        let leads = state.leads||[];

        if (leads.length == 0) {
            commit('setLeads', [lead]);
            return;
        }

        let index = leads.filter(l => l!=null).findIndex(l => l.id == lead.id);
        if (-1 !== index) {
            leads.splice(index, 1, lead);
            commit('setLeads', leads);
            return;
        } 

        /*
        let [first] = leads;
        if((first && first.id < lead.id) || leads.length == 0) {
            leads = [lead, ...leads]
        }
        commit('setLeads', leads);
        */
        
    },
    async subscribe({ dispatch, commit, state }) {
        const observer = apolloClient.subscribe({
            query: gql`
                    subscription leadUpdated {
                        leadUpdated {
                            id
                            date
                            name
                            email
                            phone
                            message
                            property {
                                code
                                title
                            }
                            origin {
                                id
                                title
                            }
                            realEstate {
                                id
                                title
                            }
                            unity {
                                id
                                title
                            }
                            department {
                                id
                                title
                            }
                            assignedTo {
                                id
                                name
                                email
                                phone
                            }
                        }
                    }
                `
        });

        observer.subscribe({
            next({ data: { leadUpdated: lead } }) {
                console.log({ lead }, 'subscription')
                //dispatch('updateLead', lead)
                //dispatch('loadLeads');
                
            },
            error(error) {
                console.error(error);
            }
        })
    }
};