<template>
    <Modal @close="close">
        <form class="filters-bar" @submit.prevent.stop="saveLead">
            <div class="row expanded form-content">
                <div class="sm-12">
                    <h3>Cadastro de Lead</h3>
                </div>

                <div class="sm-12">
                    <input type="text" placeholder="Nome" v-model="name" />
                </div>

                <div class="sm-12">
                    <input type="email" placeholder="E-mail" v-model="email" />
                </div>

                <div class="sm-12 md-6">
                    <PhoneInput placeholder="Telefone" v-model="phone"></PhoneInput>
                </div>
                <div class="sm-12 md-6">
                    <select v-model="platform_id">
                        <option value>Origem</option>
                        <option
                            :value="platform.id"
                            v-for="platform in platforms"
                            :key="platform.id"
                        >{{ platform.title }}</option>
                    </select>
                </div>

                <div class="sm-12 md-6">
                    <input type="text" v-model="property_code"  placeholder="Código do Imóvel" />
                </div>

                <div class="sm-12 md-6">
                    <input type="text" v-model="property_title"  placeholder="Título do Imóvel" />
                </div>

                
                <div class="sm-12 md-6">
                    <select v-model="unity_id">
                        <option value>Unidade</option>
                        <option
                            :value="unity.id"
                            v-for="unity in unities"
                            :key="unity.id"
                        >{{ unity.title }}</option>
                    </select>
                </div>
                <div class="sm-12 md-6">
                    <select v-model="department_id">
                        <option value>Setor</option>
                        <option
                            :value="department.id"
                            v-for="department in departments"
                            :key="department.id"
                        >{{ department.title }}</option>
                    </select>
                </div>
            </div>

            <div class="row expanded buttons">
                <div class="sm-12 flex justify-between">
                    <a @click="close" class="default-button sky">Cancelar</a>
                    <button
                        type="submit"
                        class="default-button gradient"
                        disabled
                        v-if="loading"
                    >Salvando...</button>
                    <button
                        type="submit"
                        class="default-button gradient"
                        :disabled="!canSave"
                        :title="!canSave?'Preencha todos os campos corretamente.':''"
                        v-else
                    >Salvar</button>
                </div>
            </div>
        </form>
    </Modal>
</template>
<script>
import Modal from "../../components/Modal";
import PhoneInput from "../../components/input/PhoneInput.js";

const data = () => ({
    loading: false,
    id: '',
    name: "",
    email: "",
    phone: "",
    unity_id: "",
    department_id: "",
    platform_id: '',
    property_code: '',
    property_title: ''
});

export default {
    components: {
        Modal,
        PhoneInput
    },
    props: ['lead'],
    data() {
        let row = data();
        return row;
    },
    methods: {
        close() {
            this.reset();
            this.$emit("close");
        },
        reset() {
            const initialData = data();
            Object.keys(initialData).forEach(key => this[key] = initialData[key] );

            if (1 === this.unities.length) {
                let [unity] = this.unities;
                this.unity_id = unity.id;
            }
            if (1 ===this.departments.length) {
                let [department] = this.departments;
                this.department_id = department.id;
            }
        },
        async saveLead() {
            if (!this.canSave) {
                return;
            }
            let lead = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                unity: {
                    id: this.unity_id
                },
                department: {
                    id: this.department_id
                },
                origin: {
                    id: this.platform_id
                },
                message: '',
                property: {
                    code: this.property_code,
                    title: this.property_title
                }
            };
            if (this.id != '') {
                lead.id = this.id
            }
            try {                
                this.loading = true;
                await this.$store.dispatch('leads/saveLead', lead);
                this.$emit('success');
                this.$alert("Lead salvo com sucesso!", null, 'success');
                this.reset();
                
                
            } catch (error) {
                console.error(error);
                this.$emit('error', error);
                this.$alert("Não foi possível enviar o formulário", null, 'error');
            }
            this.loading = false;
        }
    },
    computed: {
        canSave() {
            if (this.loading) {
                return false;
            }
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.email != '' && !this.email.match(emailRegex)) {
                return false;
            }
            if (this.phone != '' && this.phone.replace(/\D/gim, "").length < 10) {
                return false;
            }
            return (
                this.name != "" &&
                this.unity_id != "" &&
                this.department_id != "" &&
                this.platform_id != ""
            );
        },
        unities() {
            let unities = this.$store.state.leads.unities || [];
            if (1 === unities.length) {
                let [unity] = unities;
                this.unity_id = unity.id;
            }
            return unities;
        },
        unity() {
            const unity_id = this.unity_id;
            const unity = this.unities.find(({ id }) => id == unity_id);
            return unity;
        },
        departments() {
            const unity = this.unity;
            if (!unity) {
                return [];
            }
            let departments = unity.departments || [];
            if (1 ===departments.length) {
                let [department] = departments;
                this.department_id = department.id;
            }
            return departments
        },
        platforms() {
            let platforms = this.$store.state.leads.platforms || [];
            platforms.sort((a,b) => {
                return a.title < b.title ? -1 : 1;
            })
            return platforms;
        }
    },
    mounted() {
        if (0 == this.unities.length) {
            this.$store.dispatch("leads/loadUnities");
        }
    },
    watch: {
        lead: {
            immediate: true,
            handler(v) {
                if (v) {
                    
                    this.id = v.id;
                    this.name = v.name
                    this.email = v.email;
                    this.phone = v.phone;
                    this.unity_id = v.unity.id;
                    this.department_id = v.department.id;
                    this.platform_id = v.origin.id;
                    this.property_code = v.property.code;
                    this.property_title = v.property_title;
                    
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
    form {
        max-width: 640px;
        
        h3 {
            padding-top: 1em;
            padding-bottom: 1em;
        }
        a {
            cursor: pointer;
        }
        [disabled] {
            filter: grayscale(100%);
        }
        .form-content {
            padding-bottom: 1em;
        }
        .buttons {
            padding: 0.75em 0;
            background: rgba(0,0,0,0.05);
            border-radius: 0 0 0.25em 0.25em;
        }
    }
</style>